import { PortfolioView } from "@models/portfolio-view.model";
import { AdapterBase } from "@utilities/adapter-base";
import { PortfolioAvailabilityApi } from "@models/api/portfolio-availability-api";

export class PortfolioAvailabilityApiToPortfolioViewAdapter extends AdapterBase<PortfolioAvailabilityApi, PortfolioView> {
	public adapt(portfolio: PortfolioAvailabilityApi): PortfolioView {
		const preview = new PortfolioView();
		
		preview.portfolioId = portfolio.Id;
		preview.portfolioStage = portfolio.Stage; 
		preview.portfolioType = portfolio.Type; 
		preview.sortDate = portfolio.SortDate;
		preview.createDate = portfolio.CreateDate;
		preview.description = portfolio.Description;
		preview.entryPoint = portfolio.EntryPoint;
		preview.submittedDate = portfolio.SubmittedDate;
		preview.loanNumber = portfolio.LoanNumber;
		return preview;
	}
}