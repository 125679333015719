/* eslint-disable max-len */
import { Injectable } from '@angular/core';
import { PreApprovalForm } from '@models/form/borrower-portal/pre-approval.model';
import { PaymentForm } from '@models/form/borrower-portal/payment.model';
import { Observable } from 'rxjs/internal/Observable';
import { ConfigService } from '@services/config.service';
import { PreApprovalFormToPreApprovalApiAdapter } from '../../adapters/form-to-api/borrower-portal/pre-approval-form-to-pre-approval-api.adapter';
import { ApiService } from '@services/api.service';
import { PreApprovalApi } from '@models/api/borrower-portal/pre-approval';
import { PaymentApi } from '@models/api/borrower-portal/payment-api';
import { catchError, map } from 'rxjs/operators';
import { Tracker } from '@nbkc/tracker-angular';
import { PreApprovalButtonClickEvent } from '../../tracking/events/preapproval-button-click.event';
import { PaymentSubmissionEvent } from '../../tracking/events/payment-submission.event';
import { HttpClient, HttpEvent } from '@angular/common/http';
import { BehaviorSubject, of, throwError } from 'rxjs';
import { ErrorService } from '@services/error.service';
import { PaymentSubmissionError } from '../../tracking/errors/payment-submission.error';
import { PortfolioApi } from '@models/api/borrower-portal/portfolio-api';
import { PortfolioApiToPortfolioFormAdapter } from '../../adapters/api-to-form/portfolio-api-to-portfolio-form';
import { PortfolioForm } from '@models/form/borrower-portal/portfolio-form.model';
import { AvailabilityRequest } from '@models/request/availability-request';
import { AvailabilityResponseApi } from '@models/api/availability-response-api';
import { AvailabilityView } from '@models/availability-view.model';
import { AvailabilityResponseApiToAvailabilityViewAdapter } from '../../adapters/api-to-form/availability-response-api-to-availability-view-adapter';
import { TransferLetterButtonClickEvent } from '../../tracking/events/transfer-letter-button-click-event';
import { PortfolioFormToPortfolioApiAdapter } from '../../adapters/form-to-api/portfolio-form-to-portfolio-api-adapter';
import { ErrorServiceOptions } from '@models/error-service-options';
import { LoanOfficerStateService } from '@services/loan-officer-state-service';

declare let Accept: any;
@Injectable({
	providedIn: 'root'
})
export class PortfolioService{
	public payment: PaymentForm = new PaymentForm();
	public currentPortfolioForm: PortfolioForm;
	public showProgressAnimation: boolean = false;
	public showPaymentDialog: boolean = false;
	public showPaymentSuccessMessage: boolean = false;
	public showPaymentFailureMessage: boolean = false;
	public isPaymentComplete: boolean = false;
	public preApprovalLetter: PreApprovalForm = new PreApprovalForm();
	private subject = new BehaviorSubject<boolean>(false);
	public isLetterDownloading$: Observable<boolean> = this.subject.asObservable();
	private _preApprovalFormToPreApprovalApiAdapter: PreApprovalFormToPreApprovalApiAdapter = new PreApprovalFormToPreApprovalApiAdapter();
	private _portfolioApiToPortfolioFormAdapter: PortfolioApiToPortfolioFormAdapter = new PortfolioApiToPortfolioFormAdapter();
	private _portfolioFormToPortfolioApiAdapter: PortfolioFormToPortfolioApiAdapter = new PortfolioFormToPortfolioApiAdapter(); 
	private _availabilityResponseApiToAvailabilityViewAdapter: AvailabilityResponseApiToAvailabilityViewAdapter = new AvailabilityResponseApiToAvailabilityViewAdapter();
	

	constructor(
		private api: ApiService,
		private config: ConfigService,
		private tracker: Tracker,
		private http: HttpClient,
		private errorService: ErrorService,
		private loanOfficerStateService: LoanOfficerStateService
	) { }

	public loadAllForUser(entryUrl?: string): Observable<AvailabilityView> {
		let request = new AvailabilityRequest();
		if (entryUrl) {
			request.EntryUrl = entryUrl;
		}
		return this.api.post(`availability`, request).pipe(
			map((result: any) => {
				if (result && result.Success) {
					const availabilityResponse: AvailabilityResponseApi = result.Data;
					return this._availabilityResponseApiToAvailabilityViewAdapter.adapt(availabilityResponse);
				} else {
					return;
				}
			}),catchError((response: any) => {
				this.errorService.handle(new ErrorServiceOptions({
					data: response,			
				}));
				return throwError(response);
			})
		);
	}

	public getCreditCardToken() {
		this.showProgressAnimation = true;

		let authData: any = {};
		authData.clientKey = this.config.AUTHORIZE_NET_PUBLIC_KEY;
		authData.apiLoginID = this.config.AUTHORIZE_NET_USERNAME;

		let cardData: any = {};
		cardData.cardNumber = this.payment.creditCardNumber.replace(/\s+/g, '');
		cardData.month = this.payment.expirationDate.toString().substring(0, 2);
		cardData.year = this.payment.expirationDate.toString().substring(3);
		cardData.cardCode = this.payment.cvv;
		cardData.fullName = this.payment.nameOnCard;
		cardData.zip = this.payment.zipCode;

		let secureData: any = {};
		secureData.authData = authData;
		secureData.cardData = cardData;

		Accept.dispatchData(secureData, this.paymentTokenResponseHandler.bind(this));
	}

	public paymentTokenResponseHandler(response: { opaqueData: { dataValue: string; }, messages: { resultCode: string; message: string | any[]; }; }) {
		if (!response) {
			this.tracker.error(new PaymentSubmissionError(new Error("The response from Authorize.Net was null when attempting a payment for loan "
				+ this.payment.loanId + ".")));
			this.showProgressAnimation = false;
			this.showPaymentFailureMessage = true;
			this.resetPaymentForm();
		} else if (response.messages?.resultCode === "Error") {
			let errorMessage = "There was an error when retrieving a token from Authorize.Net for loan " + this.payment.loanId + ": ";
			let i = 0;
			while (i < response.messages?.message?.length) {
				errorMessage += response.messages?.message[i]?.code + ": " + response.messages?.message[i]?.text + "; ";
				i = i + 1;
			}
			this.tracker.error(new PaymentSubmissionError(new Error(errorMessage)));
			this.showProgressAnimation = false;
			this.showPaymentFailureMessage = true;
			this.resetPaymentForm();
		} else {
			this.tracker.event(new PaymentSubmissionEvent(
				{
					loanId: this.payment.loanId,
					amount: this.payment.amount.toString(),
					description: "User submitted a payment"
				}
			));
			this.satisfyPaymentRequest(response?.opaqueData?.dataValue).subscribe((response: any) => { });
		}
	}

	public satisfyPaymentRequest(processorToken): Observable<any> {
		const portfolioId = this.currentPortfolioForm.id;
		let payload = new PaymentApi();
		payload.ProcessorToken = processorToken;
		payload.Disclaimer = this.payment.disclaimer;
		payload.TotalAmount = this.payment.amount;

		return this.api.post(`Origination/portfolio/${portfolioId}/SatisfyRequest/${this.payment.requestId}`, payload as PaymentApi).pipe(
			map((result: any) => {
				this.resetPaymentForm();
				if (result && result.Success && result.Data?.IsSuccess) {
					this.showProgressAnimation = false;
					this.showPaymentSuccessMessage = true;
					this.isPaymentComplete = true;
					return result.Data;
				} else {
					this.tracker.error(new PaymentSubmissionError(new Error("Received an error from the API when attempting a payment for portfolio "
						+ portfolioId + ". " + result?.Message)));
					this.showProgressAnimation = false;
					this.showPaymentFailureMessage = true;
					return 'Sorry, we are unable to complete your payment at this time. Please try again later.';
				}
			}),catchError((response: any) => {
						this.errorService.handle(new ErrorServiceOptions({
							data: response,			
					}));
				return throwError(response);
			})
		)
	}

	public hidePaymentMessages() {
		this.showPaymentFailureMessage = false;
		this.showPaymentSuccessMessage = false;
		this.showProgressAnimation = false;
	}

	public resetPaymentForm() {
		this.payment.creditCardNumber = '';
		this.payment.cvv = '';
		this.payment.nameOnCard = '';
		this.payment.zipCode = '';
		this.payment.expirationDate = null;
	}

	public isPaymentMessageShowing() {
		return this.showPaymentFailureMessage === true ||
			this.showPaymentSuccessMessage === true ||
			this.showProgressAnimation === true;
	}

	public getPortfolio(portfolioId: string): Observable<PortfolioForm> {
		return this.api.get(`Origination/portfolio/${portfolioId}`).pipe(
			map((result: any) => {
				if (result && result.Success) {
					const portfolio: PortfolioApi = result.Data;
					this.currentPortfolioForm = this._portfolioApiToPortfolioFormAdapter.adapt(portfolio);
					this.loanOfficerStateService.setCurrentLoanOfficer(this.currentPortfolioForm.currentLoanOfficer); 
					return this.currentPortfolioForm;
				}
			}),catchError((response: any) => {
				this.errorService.handle(new ErrorServiceOptions({
					data: response,				
				}));
				return throwError(response);
			})
		)
	}

	public resetCurrentPortfolioValues() {
		this.currentPortfolioForm = new PortfolioForm();
		this.loanOfficerStateService.setCurrentLoanOfficer(); 
	}

	public satisfyTransferLetterRequest(): Observable<any> {
		const payload = this._portfolioFormToPortfolioApiAdapter.adapt(this.currentPortfolioForm); 

		this.tracker.event(new TransferLetterButtonClickEvent(
			{
				portfolioId: this.currentPortfolioForm.id ?? "unavailable",
			}
		));

		return this.api.post(`Origination/portfolio/${this.currentPortfolioForm.id}/SatisfyRequest`, payload as PortfolioApi).pipe(
			map((result: any) => {
				if (result && result.Success) {
					return result.Data;
				}
			})
		)
	}

	public downloadTransferLetter() {
		this.subject.next(true);
		this.satisfyTransferLetterRequest().subscribe((response: any) => {
			try {
				this.http.get(response.DownloadUrl, { responseType: 'arraybuffer' }).subscribe((data: any) => {
					const blob = new Blob([data], { type: "application/pdf" });
					let downloadURL = window.URL.createObjectURL(blob);
					let link = document.createElement('a');
					link.href = downloadURL;
					link.download = this.getFileName('NBKC-servicing-transfer');
					link.click();
					this.subject.next(false);
				});

			} catch (error) {
				this.subject.next(false);
				console.error('An error occurred', error);
				setTimeout(() => {
					this.errorService.handle(new ErrorServiceOptions({
						data: null,
						success: true,
						display: 'toast',
						message: 'Cannot download your transfer letter.',
						retryAction: () => {
							this.downloadTransferLetter();
						},
						retryText: 'Retry',
						delay: 5000
					}));
				}, 1000);
			}
		});
	}

	public downloadPreApprovalLetter() {
		this.subject.next(true);
		this.satisfyPreApprovalLetterRequest().subscribe((response: any) => {
			try {
				this.http.get(response.DownloadUrl, { responseType: 'arraybuffer' }).subscribe((data: any) => {
					const blob = new Blob([data], { type: "application/pdf" });
					let downloadURL = window.URL.createObjectURL(blob);
					let link = document.createElement('a');
					link.href = downloadURL;
					link.download = this.getFileName('NBKC-Pre-Approval');
					link.click();
					this.subject.next(false);
				});

			} catch (error) {
				this.subject.next(false);
				console.error('An error occurred', error);
				setTimeout(() => {
					this.errorService.handle(new ErrorServiceOptions({
						data: null,
						success: true,
						display: 'toast',
						message: 'Cannot download your pre-approval letter.',
						retryAction: () => {
							this.downloadPreApprovalLetter();
						},
						retryText: 'Retry',
						delay: 5000
					}));
				}, 1000);
			}
		});
	}

	public satisfyPreApprovalLetterRequest(): Observable<any> {
		const payload = this._preApprovalFormToPreApprovalApiAdapter.adapt(this.preApprovalLetter);

		this.tracker.event(new PreApprovalButtonClickEvent(
			{
				loanId: this.preApprovalLetter.loanId ?? "unavailable",
				salesPrice: this.preApprovalLetter?.salesPrice ?? "unavailable",
				loanToValue: this.preApprovalLetter?.loanToValue?.toString() ?? "unavailable",
				loanAmount: this.preApprovalLetter?.loanAmount?.toString() ?? "unavailable"
			}
		));

		return this.api.post(`Origination/loan/${this.preApprovalLetter.loanId}/SatisfyRequest`, payload as PreApprovalApi).pipe(
			map((result: any) => {
				if (result && result.Success) {
					return result.Data;
				}
			})
		)

	}

	public getFileName(name : string): string {
		const nowDate = new Date();
		const date = (nowDate.getMonth() + 1) + '-' + nowDate.getDate() + '-' + nowDate.getFullYear();
		return name + `-${date}`
	}

	public satisfyDocumentUploadRequest<TRequestData, TResponseData>(data: TRequestData, requestId: string): Observable<HttpEvent<any>> {
		return this.api.upload(`Origination/portfolio/${this.currentPortfolioForm?.id}/SatisfyRequest/${requestId}/document`, data);
	}
}

