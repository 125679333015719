import {
	TrackerEvent,
	ApplicationInsightsProviderMapping,
	ApplicationInsightsEvent
} from '@nbkc/tracker';
import { ApplicationTrackerContext } from '../application-tracker-context';

export class TransferLetterButtonClickEvent extends TrackerEvent<ApplicationTrackerContext>
    implements ApplicationInsightsProviderMapping {
    public name: string = 'transfer-letter-button-click';

    public constructor(public data: TransferLetterButtonClickEventProps) {
        super();
    }

    public ai(): ApplicationInsightsEvent {
        const event = new ApplicationInsightsEvent();
        event.name = this.name;
        event.data = {
            applicationId: this.trackerContext.applicationId,
            portfolioId: this.data.portfolioId,
        };

        return event;
    }
}

interface TransferLetterButtonClickEventProps {
    portfolioId: string;
}