import { AdapterBase, AdapterOptions } from '@utilities/adapter-base';
import { LoanForm } from '@models/form/loan.model';
import { LoanDetailsApi } from '@models/api/loan-details';
import numeral from 'numeral';
import { PortfolioTypes } from '../../constants/portfolio-types';

export class LoanFormToLoanDetailsApiAdapter extends AdapterBase<LoanForm, LoanDetailsApi> {
	adapt(source: LoanForm, options?: AdapterOptions): LoanDetailsApi {
		const myLoan = new LoanDetailsApi();
		myLoan.LoanAmount = numeral(source.amount).value();
		if (source.purpose !== PortfolioTypes.refinance && source.purpose !== PortfolioTypes.heloc) {
			myLoan.DownPayment = numeral(source.downPaymentAmount).value();
		}
		myLoan.LoanPurpose = (source.purpose === PortfolioTypes.refinance && source.cashOut) ? PortfolioTypes.cashOutRefinance : source.purpose;
		return myLoan;
	}
}
