import { CommonModule } from '@angular/common';
import { NgModule } from '@angular/core';
import { FormsModule } from '@angular/forms';
import { MatButtonModule } from '@angular/material/button';
import { MatCheckboxModule } from '@angular/material/checkbox';
import { MatIconModule } from '@angular/material/icon';
import { MatInputModule } from '@angular/material/input';
import { MatRadioModule } from '@angular/material/radio';
import { MatSlideToggleModule } from '@angular/material/slide-toggle';
import { MatSnackBarModule } from '@angular/material/snack-bar';
import { NgbModule } from '@ng-bootstrap/ng-bootstrap';
import { AppHeaderComponent } from '../components/app-header/app-header.component';
import { MobileMenuRowComponent } from '../components/app-header/mobile-menu-row/mobile-menu-row.component';
import { LoanOfficerCardComponent } from '../components/loan-officer-card/loan-officer-card.component';
import { AutoValidateDirective } from './directives/auto-validate.directive';
import { ChevronDirective } from './directives/chevron.directive';
import { ClearInvalidDateDirective } from './directives/clear-invalid-date.directive';
import { ClearZeroDirective } from './directives/clear-zero.directive';
import { ClickHelperDirective } from './directives/click-helper.directive';
import { ClickTrackerDirective } from './directives/click-tracker.directive';
import { SummaryHostDirective } from './directives/summary-host.directive';
import { AccessibilityAngularModule } from '@nbkc/accessibility-angular';
import { ComboBoxComponent } from '../components/combo-box/combo-box.component'
import { MatProgressSpinnerModule } from '@angular/material/progress-spinner';
import { MatListModule } from '@angular/material/list';
import {MatBadgeModule} from '@angular/material/badge';
import { TextMaskModule } from '../../../projects/shared-components/src/lib/utilities/angular2-text-mask/text-mask';

@NgModule({
	declarations: [
		AppHeaderComponent,
		LoanOfficerCardComponent,
		AutoValidateDirective,
		ChevronDirective,
		ClearZeroDirective,
		SummaryHostDirective,
		ClearInvalidDateDirective,
		MobileMenuRowComponent,
		ClickHelperDirective,
		ClickTrackerDirective,
		ComboBoxComponent
	],
	imports: [
		CommonModule,
		FormsModule,
		NgbModule,
		MatSlideToggleModule,
		MatSnackBarModule,
		MatCheckboxModule,
		MatRadioModule,
		MatInputModule,
		MatIconModule,
		MatButtonModule,
		AccessibilityAngularModule,
		TextMaskModule
	],
	exports: [
		AppHeaderComponent,
		LoanOfficerCardComponent,
		AutoValidateDirective,
		FormsModule,
		NgbModule,
		MatSlideToggleModule,
		MatSnackBarModule,
		MatCheckboxModule,
		MatRadioModule,
		MatInputModule,
		MatIconModule,
		MatButtonModule,
		ChevronDirective,
		ClearZeroDirective,
		SummaryHostDirective,
		ClearInvalidDateDirective,
		MobileMenuRowComponent,
		ClickHelperDirective,
		ClickTrackerDirective,
		AccessibilityAngularModule,
		ComboBoxComponent,
		MatProgressSpinnerModule,
		MatListModule,
		MatBadgeModule,
		TextMaskModule
	]
})
export class SharedModule { }
