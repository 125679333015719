import { AvailabilityResponseApi } from "@models/api/availability-response-api";
import { AvailabilityView } from "@models/availability-view.model";
import { PortfolioAvailabilityApiToPortfolioViewAdapter } from "./portfolio-api-to-portfolio-view-adapter";
import { ProductAvailabilityApiToProductViewAdapter } from "./product-availability-api-to-product-view-adapter";
import { AdapterBase } from "@utilities/adapter-base";

export class AvailabilityResponseApiToAvailabilityViewAdapter extends AdapterBase<AvailabilityResponseApi, AvailabilityView> {
	public adapt(availability: AvailabilityResponseApi): AvailabilityView{
		const preview = new AvailabilityView();

        let portfolioAdapter = new PortfolioAvailabilityApiToPortfolioViewAdapter(); 
        let productAdapter = new ProductAvailabilityApiToProductViewAdapter(); 
		
		preview.portfolioViews = portfolioAdapter.adaptCollection(availability.Portfolios);
        preview.productViews = productAdapter.adaptCollection(availability.Products); 

		return preview;
	}
}