import { Injectable } from '@angular/core';
import { MatSnackBar, MatSnackBarRef, SimpleSnackBar } from '@angular/material/snack-bar';
import { Router } from '@angular/router';
import { Tracker } from '@nbkc/tracker-angular';
import { ConfigService } from './config.service';
import { GeneralError } from '../tracking/errors/general.error';
import { HttpStatusCodeHandler} from '@utilities/responses/http-status-code-handler';
import { ErrorServiceOptions } from '@models/error-service-options';
import { KnownRoutes } from '@constants';

@Injectable({
	providedIn: 'root'
})
export class ErrorService {
	public currentErrorState: ApplicationErrorState = null;
	private snacky: MatSnackBarRef<SimpleSnackBar>;

	constructor(
		private config: ConfigService,
		private router: Router,
		private snackBar: MatSnackBar,
		private tracker: Tracker
	) {
	}

	public handle(options: ErrorServiceOptions): void {
		options = HttpStatusCodeHandler.updateOptionsOnStatusCode(options); 

		if (options.data && !options.success) {
			this.tracker.error(new GeneralError(new Error(JSON.stringify({
				data: JSON.stringify(options.data),
				display: options.display,
				message: options.message,
				retryText: options.retryText,
				redirect: options.redirectOnProd
			}))));
		}
		
		if (options.redirectOnProd && this.config.DEBUG === 'false') {
			this.redirectUserToSafety(options);
		} else {
			this.showErrorMessageOnDisplay(options);
		}
	}

	public redirectUserToSafety(filledOptions: ErrorServiceOptions): void {
		if (filledOptions.redirectOnProd) {
			this.router.navigate(filledOptions.redirectOnProd);
		}
	}

	public showErrorMessageOnDisplay(filledOptions: ErrorServiceOptions): void {
		if (filledOptions.display === 'error-page') {
			this.currentErrorState = {
				debugDetails: [filledOptions.data || null].filter(Boolean),
				isReloading: false,
				title: filledOptions.title,
				message: filledOptions.message,
				retryText: filledOptions.retryText,
				retryAction: filledOptions.retryAction
			};
			this.router.navigate([KnownRoutes.Error]);
		} else if (filledOptions.display === 'toast') {
			const toastClass = (filledOptions.success) ? 'nbkc-success' : 'nbkc-error';
			this.snacky = this.snackBar.open(filledOptions.message, filledOptions.retryText.toUpperCase(), {
				duration: filledOptions.delay,
				horizontalPosition: 'right',
				verticalPosition: 'bottom',
				panelClass: toastClass
			});

			this.snacky.onAction().subscribe(() => {
				if (filledOptions.retryAction) {
					filledOptions.retryAction();
				}
			});
		} else {
			// eslint-disable-next-line no-console
			console.log('Error: ' + filledOptions.message + '\n\n' + filledOptions.data);
		}
	}

	public dismiss(): void {
		if (this.snacky) {
			this.snacky.dismiss();
		}
	}
}

interface ApplicationErrorState {
	debugDetails: Error[];
	isReloading: boolean;
	title: string;
	message: string;
	retryText: string;
	retryAction: () => void;
}
