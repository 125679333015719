export class PortfolioTypes {
	public static purchase: string = 'Purchase';
	public static noCashOutRefinance: string = 'NoCashOutRefinance';
	public static refinance: string = 'Refinance';
	public static unknown: string = 'Unknown';
	public static preApproval: string = 'PreApproval';
	public static cashOutRefinance: string = 'CashOutRefinance';
	public static heloc: string = 'HELOC';
	public static homeEquityLineOfCredit: string = 'HomeEquityLineOfCredit';

	public static matchPurpose(purpose : string) : string {
		let matchResult: string = '';

		if(!purpose){
			return matchResult;
		}

		switch(purpose.toLowerCase()){
			case PortfolioTypes.purchase.toLowerCase() :
				matchResult = PortfolioTypes.purchase;
				break;
			case PortfolioTypes.noCashOutRefinance.toLowerCase() :
				matchResult = PortfolioTypes.refinance;
				break;
			case PortfolioTypes.refinance.toLowerCase() :
				matchResult = PortfolioTypes.refinance;
				break;
			case PortfolioTypes.cashOutRefinance.toLowerCase() :
				matchResult = PortfolioTypes.cashOutRefinance;
				break;
			case PortfolioTypes.heloc.toLowerCase() : 
				matchResult = PortfolioTypes.heloc;
				break;
			case PortfolioTypes.homeEquityLineOfCredit.toLowerCase() : 
				matchResult = PortfolioTypes.heloc;
				break;
			default :
				break; 
		}

		return matchResult;
	}
}
