export class DocumentRow {
	public PortfolioId: string;
	public RequestId: string;
	public DocumentId: string;
	public ApplicationId: string;
	public DocumentType: string;
	public Filename: string;
	public CreatedDt: Date;
	public DocumentStatus: string;
	public downloaded: boolean;
	public downloading: boolean;
}
