import { PortfolioLoanServicingSummaryApi } from "@models/api/borrower-portal/portfolio-loan-servicing-summary-api";
import { PortfolioLoanServicingForm } from "@models/form/borrower-portal/portfolio-loan-servicing-form";
import { AdapterBase } from "@utilities/adapter-base";

export class PortfolioServicingApiToPortfolioServicingFormAdapter extends AdapterBase<PortfolioLoanServicingSummaryApi, PortfolioLoanServicingForm> {
    public adapt(portfolioServicingApi : PortfolioLoanServicingSummaryApi) : PortfolioLoanServicingForm {
        let portfolioServicing = new PortfolioLoanServicingForm(); 
        portfolioServicing.transferDate = portfolioServicingApi.TransferDate;

        return portfolioServicing; 
    }
}