import { AdapterBase, AdapterOptions } from '@utilities/adapter-base';
import { EmployerApi } from '@models/api/employer';
import { EmploymentForm } from '@models/form/employment.model';
import { AddressApiToAddressFormAdapter } from './address-api-to-address-form.adapter';
import { DateHelper, NumberHelper } from '@utilities/helpers';

export class EmployerApiToEmploymentFormAdapter extends AdapterBase<EmployerApi, EmploymentForm> {
	private _addressApiToAddressFormAdapter: AddressApiToAddressFormAdapter = new AddressApiToAddressFormAdapter();

	public adapt(source: EmployerApi, options: CurrentAdapterOptions): EmploymentForm {
		const dest = new EmploymentForm(options.borrowerIndex, options.isRefinance);
		dest.monthlyIncome = this.calculateMonthlyIncome(source.AnnualBaseSalary);
		dest.annualBonusIncome = NumberHelper.numberToString(source.AnnualBonus);
		dest.annualCommissionIncome = NumberHelper.numberToString(source.AnnualCommission);
		dest.annualOvertimeIncome = NumberHelper.numberToString(source.AnnualOvertime);
		dest.startDate = DateHelper.dateToString(source.StartDate);
		dest.endDate = DateHelper.dateToString(source.EndDate);
		dest.address = this._addressApiToAddressFormAdapter.adapt(source.Address);
		dest.address.requireCounty = false;
		dest.isCurrent = source.CurrentEmployment;
		dest.companyName = source.BusinessName;
		dest.jobTitle = source.Title;
		dest.phone = (source.Phone) ? source.Phone.Number : null;
		dest.selfEmployed = source.SelfEmployed;
		dest.isFamily = source.EmployerIsFamily;
		dest.isPartyToTransaction = source.EmployerIsPartyToTransaction;
		dest.ownershipPercentage = source.OwnershipPercentage;
		dest.yearsInCareer = source.TimeInLineOfWorkYears;
		return dest;
	}

	private calculateMonthlyIncome(annualBaseSalary: number): string {
		return NumberHelper.numberToString(annualBaseSalary/12)
	}

}

interface CurrentAdapterOptions extends AdapterOptions {
	borrowerIndex: number;
	isRefinance: boolean;
}
