<app-app-header></app-app-header>
<div class="section application-status-page" role="main" aria-labelledby="applicationSectionTitle">
	<div class="nbkc-card-section">
		<div *ngIf="transferLetterExists" >
			<div class="nbkc-section-heading">
				<h1 id="applicationSectionTitle" class="nbkc-section-title">
					Your loan is complete!
				</h1>
				<div class="nbkc-section-subheading">
					<span>
						We appreciate your business. Let us know how we can assist in your future home loan needs. 
					</span>
				</div>
			</div>
		</div>
		<div *ngIf="!transferLetterExists">
			<div class="nbkc-section-heading">
				<h1 id="applicationSectionTitle" class="nbkc-section-title">
					Complete your tasks to keep your loan moving
				</h1>
				<div class="nbkc-section-subheading">
					<span>
						Now that your loan has been started, we need some details to proceed with your loan. 
					</span>
				</div>
			</div>
		</div>
		<div class="separator">Items we need from you</div>
		<div *ngIf="!!preApprovalRequest">
			<lib-card-component [headerIcon]="'fal fa-file-invoice-dollar'" data-cy="pre-approval-card-component"
				[headerTitle]="preApprovalRequest.displayName" [contentBody]="preApprovalRequest.description">
				<div *ngIf="isCardExpanded" card-content>
					<app-pre-approval-letter-form #PreApprovalFormComponent card-content
						[preApprovalRequest]="preApprovalRequest" (buttonDisabledEvent)="disableButtonToggle($event)">
					</app-pre-approval-letter-form>
				</div>
				<lib-button card-footer-button *ngIf="!isCardExpanded" (click)="expandToggleButtonClicked()"
					class="material-button-no-padding lib-button lib-left-action-1" [id]="'lib-left-action-1'"
					[displayText]="'Customize Letter'"></lib-button>
				<lib-button card-footer-button *ngIf="isCardExpanded" (click)="expandToggleButtonClicked()"
					class="material-button-no-padding lib-button lib-left-action-2" [id]="'lib-left-action-2'"
					[displayText]="'Cancel'"></lib-button>
				<lib-button card-footer-button class="material-button-no-padding lib-button lib-right-action"
					[id]="'lib-right-action'" [isDisabled]="isLoading || isButtonDisabled"
					[displayText]="'Download Letter'" (click)="rightActionButtonClicked()">
					<mat-spinner diameter='20' style="left: 50%; z-index: 1; position: absolute;"
						*ngIf="isLoading"></mat-spinner>
				</lib-button>
			</lib-card-component>
		</div>
		<div *ngIf="isDocMagicWidgetEnabled">
			<div ng-if="!!eSignRequests" *ngFor="let eSignRequest of eSignRequests">
				<lib-card-component class="widget"
					[ngClass]="{'hidden': showESignDialog === true && eSignRequest.id === filteredESignRequest.id}"
					data-cy="docMagic-card-component" [headerIcon]="'fal fa-signature'"
					[headerTitle]="eSignRequest.displayName" [contentBody]="eSignRequest.description">
					<lib-button card-footer-button class="material-button-no-padding lib-button lib-right-action"
						[id]="'lib-right-action'" [displayText]="'View Documents'"
						[badgeContent]="getBadgeContent(eSignRequest)"
						[isBadgeHidden]="getBadgeContent(eSignRequest) === 0"
						(click)="displayESignDialog(eSignRequest)">
					</lib-button>
				</lib-card-component>
			</div>
		</div>
		<div *ngIf="isPaymentWidgetEnabled">
			<div *ngIf="isPaymentRequired()">
				<lib-card-component [headerIcon]="'fal fa-cash-register'" data-cy="payment-card-component"
					[headerTitle]="paymentRequest.displayName" [headerSubTitle]="paymentRequest.description"
					[contentBody]="'Click below to submit your payment and keep your application on track. '">
					<lib-button card-footer-button class="material-button-no-padding lib-button lib-right-action"
						[id]="'open-payment-modal-button'" (click)="showPaymentRequestDialog()"
						[displayText]="'Pay Now'">
					</lib-button>
				</lib-card-component>
			</div>
		</div>
		<!-- Doc upload component -->
		<div ng-if="!!documentRequests" *ngFor="let request of documentRequests">
			<app-document-upload 
				[documentUploadRequest]="request" 
				data-cy="doc-upload-card-component">
			</app-document-upload>
		</div>
		<div *ngIf="transferLetterExists">
			<lib-card-component [headerIcon]="'fal fa-cash-register'" data-cy="transfer-letter-card-component"
					headerTitle="Notice of Transfer of Servicing" headerSubTitle="getLoanTransferDate()"
					[contentBody]="transferLetterCardBody()">
					<lib-button card-footer-button class="material-button-no-padding lib-button lib-right-action"
					[id]="'download-transfer-letter-button'" (click)="downloadTransferLetter()"
					displayText="Download Letter">
					</lib-button>
				</lib-card-component>
		</div>
	</div>
	<app-loan-officer-card *ngIf="loanOfficer" [loanOfficer]="loanOfficer">
	</app-loan-officer-card>
</div>
<lib-modal [(visible)]="showESignDialog" data-cy="docMagic-modal-component">
	<div *ngIf="filteredESignRequest">
		<lib-card-component data-cy="docMagic-card-component" [headerIcon]="'fal fa-signature'"
			[headerTitle]="filteredESignRequest.displayName" [contentBody]="filteredESignRequest.description">
			<app-e-sign card-content [eSignPackages]="filteredESignRequest.detail.Packages">
			</app-e-sign>
			<lib-button card-footer-button *ngIf="!showESignDialog"
				class="material-button-no-padding lib-button lib-right-action" [id]="'lib-right-action'"
				[displayText]="'View Documents'" (click)="showESignDialog = !showESignDialog">
			</lib-button>
			<lib-button card-footer-button *ngIf="showESignDialog"
				class="material-button-no-padding lib-button lib-right-action" [id]="'lib-right-action'"
				[displayText]="'Close'" (click)="showESignDialog = !showESignDialog">
			</lib-button>
		</lib-card-component>
	</div>
</lib-modal>
<lib-modal *ngIf="!!paymentRequest" [(visible)]="!!paymentRequest && portfolioService.showPaymentDialog"
	(visibleChange)="onPaymentRequestDialogVisibilityChange($event)">
	<lib-card-component [headerIcon]="'fal fa-cash-register'" [headerTitle]="paymentRequest.displayName"
		[headerSubTitle]="paymentRequest.description" [contentBody]="' '">
		<app-payment-form card-content [paymentRequest]="paymentRequest"></app-payment-form>
	</lib-card-component>
</lib-modal>