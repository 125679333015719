import { Validatable, Validate, ValidateIf, Validators } from '@nbkc/validation';
import { AddressForm } from './address.model';
import { FutureDateRule } from '@utilities/rules/future-date-rule';

export class EmploymentForm extends Validatable {
	public borrowerIndex: number;
	@ValidateIf('this.isCurrent === false', new FutureDateRule('Please enter the last date of employment.'))
	public endDate: string | null;
	public isCurrent: boolean;
	public phone: string | null;
	public selfEmployed: boolean;
	public isFamily: boolean;
	public isPartyToTransaction: boolean;
	@ValidateIf("this.selfEmployed === true", Validators.required('Please enter your ownership percentage'))
	public ownershipPercentage: number;
	@Validate(new FutureDateRule(' Please enter the first date of employment.'))
	public startDate: string | null;
	@Validate(Validators.required('Please enter an employer name.'))
	public companyName: string;
	@ValidateIf('this.hasAddress === true', Validators.required())
	public address: AddressForm;
	public annualBaseIncome: string | null;
	public annualBonusIncome: string | null;
	public annualCommissionIncome: string | null;
	public annualOvertimeIncome: string | null;
	@ValidateIf('this.isCurrent === true', Validators.required('Please enter a job title.'))
	public jobTitle: string | null;
	@ValidateIf('this.isCurrent === true && this.isRefinance === false', Validators.requiredZeroIsValid('Please enter an amount.'))
	public yearsInCareer: number | null;
	@ValidateIf('this.isCurrent === true && this.isRefinance === false', Validators.requiredZeroIsValid('Please enter an amount.'))
	public monthlyIncome: string | null;

	public isRefinance: boolean;

	constructor(borrowerIndex: number, isRefinance : boolean = false) {
		super();
		this.borrowerIndex = borrowerIndex;
		this.isRefinance = isRefinance;
		this.address = new AddressForm({ requireCounty: false });
	}

	public get hasAddress(): boolean {
		return !!this.address.fullAddress;
	}

	public getAnnualBaseIncome( monthlyIncome: number): number {
		return monthlyIncome * 12;
	}
}
