export class Constants {
	static readonly ChoiceListNames: Record<string, string> =
	{
		LoanOfficers: 'LoanOfficers'
	}

	static readonly ContactPhoneTypes: Record<string, string> = 
	{
		Unknown: 'Unknown',
		Home: 'Home',
		Work: 'Work',
		Mobile: 'Mobile',
		Other: 'Other',
		Fax: 'Fax'
	}
}
