import { AdapterBase } from '@utilities/adapter-base';
import { LoanForm } from '@models/form/loan.model';
import { LoanDetailsApi } from '@models/api/loan-details';
import { NumberHelper } from '@utilities/helpers';
import { PortfolioTypes } from '../../constants/portfolio-types';

export class LoanDetailsApiToLoanFormAdapter extends AdapterBase<LoanDetailsApi, LoanForm> {
	public adapt(source: LoanDetailsApi): LoanForm {
		const loanForm = new LoanForm();
		loanForm.purpose = source.LoanPurpose;
		loanForm.cashOut = false;
		if (loanForm.purpose === PortfolioTypes.cashOutRefinance) {
			loanForm.purpose = PortfolioTypes.refinance;
			loanForm.cashOut = true;
		}
		loanForm.downPaymentAmount = NumberHelper.numberToString(source.DownPayment);
		loanForm.amount = NumberHelper.numberToString(source.LoanAmount);
		return loanForm;
	}
}
