import { PortfolioApi } from "@models/api/borrower-portal/portfolio-api";
import { PortfolioForm } from "@models/form/borrower-portal/portfolio-form.model";
import { AdapterBase } from "@utilities/adapter-base";

export class PortfolioFormToPortfolioApiAdapter extends AdapterBase<PortfolioForm, PortfolioApi> {
    adapt(source: PortfolioForm): PortfolioApi {
        const dest = new PortfolioApi();
        dest.Name = 'transfer-letter';
        dest.Id = source.id;
        dest.Stage = source.stage;

        return dest;
    }
}