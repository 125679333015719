import { HttpErrorResponse } from "@angular/common/http";
import { ErrorServiceOptions } from "@models/error-service-options";
import { KnownRoutes } from '@constants';

export class HttpStatusCodeHandler{

    /**
     * The hashmap for handling individual status codes.
     */
    private static httpStatusCodeResponseMap : Map<number, (options: ErrorServiceOptions) => ErrorServiceOptions> = new Map([
		[400, (options: ErrorServiceOptions) => {
			options.redirectOnProd = [KnownRoutes.Home];
			return options;
		}],
        [401, (options: ErrorServiceOptions) => {
			options.redirectOnProd = [KnownRoutes.Home];
			return options;
		}],
        [403, (options: ErrorServiceOptions) => {
			options.redirectOnProd = [KnownRoutes.Home];
			return options;
		}]
	]);

    /**
     * checks to see if we have a specific scenario for the given status code
     * @param options the options to be updated
     * @returns a revised version of the options passed in if we find a match for the status code on the options object
     */
    public static updateOptionsOnStatusCode(options: ErrorServiceOptions): ErrorServiceOptions{
        if(options.data 
            && options.data instanceof HttpErrorResponse 
            && this.httpStatusCodeResponseMap.has(options.data.status)){
                options = this.httpStatusCodeResponseMap.get(options.data.status)(options);
        }

        return options;
    }
}