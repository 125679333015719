import { Validatable, Validate, Validators } from '@nbkc/validation';

export class OtherIncomeForm extends Validatable {
	public borrowerIndex: number;
	@Validate(Validators.required('Please select an income type.'))
	public type: string;
	@Validate(Validators.required('Please enter a monthly income amount.'))
	public monthlyAmount: string;
	@Validate(Validators.maxLength(45, 'Description should be less than 45 characters.'))
	@Validate(Validators.required('Please enter a description.'))
	public description: string;

	constructor(borrowerIndex: number) {
		super();
		this.borrowerIndex = borrowerIndex;
	}
}
