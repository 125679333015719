import { Component, Input, OnDestroy, OnInit } from '@angular/core';
import { Tracker } from '@nbkc/tracker-angular';
import { LoanOfficer } from '@models/loan-officer.model';
import { AppState } from '@services/app-state.service';
import { GrammarService } from '@services/grammar.service';
import {
	LoCardEmailEvent,
	LoCardHideEvent,
	LoCardMobilePhoneEvent,
	LoCardNMLSEvent,
	LoCardOfficePhoneEvent,
	LoCardShowEvent,
} from '../../../../src/app/shared/tracking/events';
import { LoanOfficerStateService } from '@services/loan-officer-state-service';
import { Subscription } from 'rxjs';

@Component({
	selector: 'app-loan-officer-card',
	templateUrl: './loan-officer-card.component.html',
	styleUrls: ['./loan-officer-card.component.scss']
})
export class LoanOfficerCardComponent implements OnInit, OnDestroy {
	public loanOfficer: LoanOfficer;

	private _navigationServiceSubscription: Subscription;

	constructor(
		public grammarService: GrammarService,
		public tracker: Tracker,
		public appState: AppState,
		public loanOfficerStateService: LoanOfficerStateService
	) {
		if (this.appState.loanOfficerCardShown === undefined) {
			this.appState.loanOfficerCardShown = false;
		}
	}

	ngOnInit(): void {
		this._navigationServiceSubscription = this.loanOfficerStateService.getCurrentLoanOfficer().subscribe((result: LoanOfficer) => {
			this.loanOfficer = result;
		});
	}

	public toggleLoanOfficer = () => {
		this.appState.loanOfficerCardShown = !this.appState
			.loanOfficerCardShown;
		if (this.appState.loanOfficerCardShown) {
			this.tracker.event(
				new LoCardShowEvent({
					loEmail: this.loanOfficer.loanOfficerEmail
				})
			);
		} else {
			this.tracker.event(
				new LoCardHideEvent({
					loEmail: this.loanOfficer.loanOfficerEmail
				})
			);
		}
	}

	public nmlsClick = () => {
		return this.tracker.event(
			new LoCardNMLSEvent({
				loEmail: this.loanOfficer.loanOfficerEmail
			})
		);
	}

	public officePhoneClick = () => {
		return this.tracker.event(
			new LoCardOfficePhoneEvent({
				loEmail: this.loanOfficer.loanOfficerEmail
			})
		);
	}

	public mobilePhoneClick = () => {
		return this.tracker.event(
			new LoCardMobilePhoneEvent({
				loEmail: this.loanOfficer.loanOfficerEmail
			})
		);
	}

	public emailClick = () => {
		return this.tracker.event(
			new LoCardEmailEvent({
				loEmail: this.loanOfficer.loanOfficerEmail
			})
		);
	}

	ngOnDestroy(): void {
		this._navigationServiceSubscription.unsubscribe();
	}
}
