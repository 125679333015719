import { Component, OnInit, OnDestroy } from '@angular/core';
import { ConsumerConnectWidgetConfig } from '@models/consumer-connect-widget-config.model';
import { ConfigService } from '@services/config.service';
import { ErrorService } from '@services/error.service';
import * as Bowser from 'bowser';

@Component({
	selector: 'app-consumer-connect',
	templateUrl: './consumer-connect.component.html',
	styleUrls: ['./consumer-connect.component.scss']
})
export class ConsumerConnectComponent implements OnInit, OnDestroy {
	public siteId: string;
	public widgetConfig: string;
	constructor(
		public errorService: ErrorService,
		public config: ConfigService
	) {

	}

	ngOnInit(): void {
		let script = document.createElement('script');
			script.type = 'text/javascript';
			script.src = 'https://widget.ellieservices.com/latest/launcher.js';
			document.head.appendChild(script);

		this.siteId = this.config.CONSUMER_CONNECT_SITE_ID;

		const widgetConfig = new ConsumerConnectWidgetConfig();
		widgetConfig.externalLoginWindow = this.requiresExternalLoginWindow();

		this.widgetConfig = JSON.stringify(widgetConfig);
	}

	ngOnDestroy(): void {
		this.errorService.dismiss();
	}

	private requiresExternalLoginWindow() : boolean {
		const browser = Bowser.getParser(window.navigator.userAgent);

		if (this.config.DEBUG && this.config.DEBUG === 'true') {
			console.log(browser.getBrowser());
		}

		return browser.satisfies({
			macos: {
			  safari: ">13"
			},

			mobile: {
				safari: '>=13'
			},

		  }) ?? false;
	}
}
