import { Component, OnInit, OnDestroy } from '@angular/core';
import { Router } from '@angular/router';
import { PortfolioTypes, CacheKeys, SectionNames } from '@constants';
import { Lookups } from '@lookups/lookups';
import { Tracker } from '@nbkc/tracker-angular';
import { NgbModal } from '@ng-bootstrap/ng-bootstrap';
import { AppState } from '@services/app-state.service';
import { ApplicationService } from '@services/application.service';
import { ErrorService } from '@services/error.service';
import { GrammarService } from '@services/grammar.service';
import { UserService } from '@services/user.service';
import { convertCollectionToMap } from '@utilities/collection-to-map';
import _ from 'lodash';
import moment from 'moment';
import { finalize } from 'rxjs/operators';
import { ConfirmModalComponent } from './confirm-modal/confirm-modal.component';
import { LoanApplicationStartedEvent } from 'src/app/shared/tracking/events';
import { ConfigService } from '@services/config.service';
import { CacheService } from '@services/cache.service';
import { PortfolioService } from '@services/borrower-portal/portfolio.service';
import { PortfolioView } from '@models/portfolio-view.model';
import { AvailabilityView } from '@models/availability-view.model';
import { ProductView } from '@models/product-view.model';
import { NEW_PRODUCT_CARDS } from './constants/new-product-cards';

@Component({
  selector: 'app-landing-page',
  templateUrl: './landing-page.component.html',
  styleUrls: ['./landing-page.component.scss']
})
export class LandingPageComponent implements OnInit, OnDestroy {
	public products: ProductView[];
  	public portfolios: PortfolioView[];
	public mostRecentPortfolio: PortfolioView;
	public submittedApps: PortfolioView[] = [];
	public inProgressApps: PortfolioView[] = [];
	public portfolioTypeMap: Map<string, string> = convertCollectionToMap<string, string>(Lookups.PortfolioTypes, 'id', 'description');
	public isHelocEnabled: boolean = false;

	public purchaseIcon: string = "fa fa-home";
	public refinanceIcon: string = "fa fa-balance-scale";
	public cashOutRefinanceIcon: string = "fa fa-money-bill-wave"
	public helocIcon: string = "fa fa-piggy-bank";
	
	constructor(
		public appState: AppState,
		public applicationService: ApplicationService,
		public portfolioService: PortfolioService,
		public errorService: ErrorService,
		public grammarService: GrammarService,
		public userService: UserService,
		public router: Router,
		public modalService: NgbModal,
		public tracker: Tracker,
		public config: ConfigService,
		private cacheService: CacheService,
	) {
	}

	ngOnInit(): void {
		if(this.config.HELOC_FEATURE_FLAG_ENABLED?.toLowerCase() === 'true'){
			this.isHelocEnabled = true;
		}
		this.loadAll();
	}

	public loadAll(): void {
		this.portfolioService.resetCurrentPortfolioValues(); 
		const appPurpose : string = PortfolioTypes.matchPurpose(this.cacheService.get(CacheKeys.requestedPortfolioType));
		this.cacheService.clear(CacheKeys.requestedPortfolioType);
		const entryUrl : string = this.cacheService.get(CacheKeys.entryUrl);
		this.appState.notReady();
		this.appState.loading('Gathering all of your applications');
		this.portfolioService.loadAllForUser(entryUrl).pipe(finalize(() => {
			this.appState.ready();
			this.appState.notLoading();
		})).subscribe((response: AvailabilityView) => {
			if (response) {
				const availability = response;
				if (!availability.portfolioViews || availability.portfolioViews.length === 0 && appPurpose) {
					this.startCreateNewApplicationFlow(appPurpose)
				}else{
					this.portfolios = availability.portfolioViews;
					this.products = availability.productViews;
					this.mostRecentPortfolio = _.orderBy(this.portfolios, (app: PortfolioView) => {
						return app.sortDate;
					}, 'desc')[0];
					_.forEach(availability.portfolioViews, (app: PortfolioView) => {
						if (app.submittedDate) {
							this.submittedApps.push(app);
						} else {
							this.inProgressApps.push(app);
						}
					});
				}
			}
		}, (response) => {
			this.errorService.handle({
				data: response,
				display: 'error-page',
				message: 'Cannot load your applications. Use the button below to try again.',
				retryAction: () => {
					this.router.navigate(['status']);
				},
				retryText: 'Load Your Applications'
			});
		});
	}

	public startCreateNewApplicationFlow(purpose: string): void {
		const mostRecentApplicationAge = moment().diff(moment(this.mostRecentPortfolio?.sortDate as any), 'days');
		if (this.mostRecentPortfolio && mostRecentApplicationAge < 30) {
			const modalRef = this.modalService.open(ConfirmModalComponent, {
				windowClass: 'loan-app-modal'
			});
			modalRef.componentInstance.mostRecentApplication = this.mostRecentPortfolio;
			modalRef.result.then(() => {
				this.createNewApplication(purpose);
			}, (reason) => {
				if (reason.goToApplication) {
					this.viewApp(this.mostRecentPortfolio);
				}
				if (reason.goToDocumentUploads) {
					this.router.navigate(['borrower-portal', this.mostRecentPortfolio.portfolioId]);
				}
			});
		} else {
			this.createNewApplication(purpose);
		}
	}

	public createNewApplication(purpose: string): void {
		this.portfolioService.setCurrentLoanOfficer(); 
      	this.applicationService.createNewAppAndRouteToIt(purpose).subscribe(() => {
        	this.tracker.event(new LoanApplicationStartedEvent(purpose));
      	});
	}

	public viewApp(portfolio: PortfolioView): void {
		this.appState.loading('Loading your application');
		if (portfolio.portfolioType) {
			this.router.navigate([portfolio.entryPoint, SectionNames.loan]).then(() => this.appState.notLoading());
		}
	}

	public viewSubmittedApp(portfolio: PortfolioView): void {
		this.appState.loading('Loading your application');
		setTimeout(() => {
			this.router.navigate([portfolio.entryPoint]).then(() => this.appState.notLoading());
		}, 1250);
	}

	public getLoanDisplayText(portfolio: PortfolioView): string {
		return portfolio.description;
	}

	public getLoanIcon(portfolioType: string): string{
		let returnIcon : string = "";
		switch(portfolioType.toLowerCase()){
			case PortfolioTypes.purchase.toLowerCase(): 
				returnIcon = this.purchaseIcon; 
				break;
			case PortfolioTypes.cashOutRefinance.toLowerCase(): 
				returnIcon = this.cashOutRefinanceIcon; 
				break; 
			case PortfolioTypes.noCashOutRefinance.toLowerCase(): 
				returnIcon = this.refinanceIcon;
				break; 
			case PortfolioTypes.refinance.toLowerCase(): 
				returnIcon = this.refinanceIcon;
				break;
			case PortfolioTypes.heloc.toLowerCase(): 
				returnIcon = this.helocIcon;
				break;
			case PortfolioTypes.homeEquityLineOfCredit.toLowerCase(): 
				returnIcon = this.helocIcon;
				break;
			default: 
				break;
		}

		return returnIcon; 
	}

	ngOnDestroy(): void {
		this.errorService.dismiss();
	}

	public readonly NEW_PRODUCT_CARDS = NEW_PRODUCT_CARDS; 
}
