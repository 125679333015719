import { NgModule } from '@angular/core';
import { RouterModule, Routes } from '@angular/router';
import { AuthGuard } from './auth.guard';
import { ConsumerConnectComponent } from './components/consumer-connect/consumer-connect.component';
import { ErrorComponent } from './components/error/error.component';
import { LoginComponent } from './components/login/login.component';
import { MaintenanceComponent } from './components/maintenance/maintenance.component';
import { LoGridGuard } from './lo-grid.guard';
import { MyAppsGuard } from './my-apps.guard';
import { SyncGuardHelper } from '@utilities/sync-guard-helper';
import { LogOutComponent } from './components/log-out/log-out.component';
import { LandingPageComponent } from './components/landing-page/landing-page.component';
import { BorrowerPortalComponent } from './components/borrower-portal/borrower-portal.component';

export const TopLevelRoutes: Routes = [
	{
		path: '',
		component: LandingPageComponent,
		canActivate: [SyncGuardHelper],
		data: {
			syncGuards: [
				AuthGuard,
				MyAppsGuard
			]
		}
	},
	{
		path: 'lo',
		loadChildren: () => import('./components/lo-grid/lo-grid.module').then((mod) => mod.LoGridModule),
		canActivate: [SyncGuardHelper],
		data: {
			syncGuards: [
				AuthGuard,
				LoGridGuard
			]
		}
	},
	{
		path: 'error',
		component: ErrorComponent
	},
	{
		path: 'borrower-portal/:id',
		component: BorrowerPortalComponent,
		canActivate: [AuthGuard]
	},
	{
		path: 'apply',
		loadChildren: () => import('./components/apply/apply.module').then((mod) => mod.ApplyModule),
		canActivate: [AuthGuard]
	},
	{
		path: 'documentportal',
		component: ConsumerConnectComponent,
		canActivate: [AuthGuard]
	},
	{
		path: 'app-offline',
		component: MaintenanceComponent
	},
	{
		path: 'login',
		component: LoginComponent
	},
	{
		path: 'logout',
		component: LogOutComponent
	},
	{
		path: '**',
		redirectTo: ''
	}
];

@NgModule({
	imports: [RouterModule.forRoot(TopLevelRoutes)],
	exports: [RouterModule]
})
export class AppRoutingModule {
}
