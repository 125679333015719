import { InternalContact } from "@models/api/borrower-portal/internal-contact";
import { LoanOfficer } from "@models/loan-officer.model";
import { PortfolioInternalContactToLoanOfficerAdapter } from "../adapters/api-to-form/portfolio-internal-contact-to-loan-officer-adapter";
import { Injectable } from "@angular/core";
import { BehaviorSubject, Observable } from "rxjs";

@Injectable({
    providedIn: 'root',
})
export class LoanOfficerStateService {

    private _currentLoanOfficer : BehaviorSubject<LoanOfficer>; 

    private _internalContactToLoanOfficerAdapter: PortfolioInternalContactToLoanOfficerAdapter = new PortfolioInternalContactToLoanOfficerAdapter();
    
    constructor() {
        this._currentLoanOfficer = new BehaviorSubject<LoanOfficer>(this.getDefaultLoanOfficer()); 
    }

    public getCurrentLoanOfficer(): Observable<LoanOfficer>{
        return this._currentLoanOfficer.asObservable(); 
    }

    /**
   * @remarks
   * Takes a nullable loan officer as either a {@link LoanOfficer} or an {@link InternalContact}.
   * Sets the current loan officer for the given loan equal to the one passed in, converting the parameter to a {@link LoanOfficer} object if necessary.
   * If no loan officer is provided, then set the current loan officer to the default {@link LoanOfficer} value.
   *
   * @param loanOfficer - Nullable loan officer object.
   */
    public setCurrentLoanOfficer(loanOfficer?: LoanOfficer);
    public setCurrentLoanOfficer(loanOfficer?: InternalContact);
    public setCurrentLoanOfficer(loanOfficer?: LoanOfficer | InternalContact ) {
        if (loanOfficer) {
            if (this.isInternalContact(loanOfficer)) {
                this._currentLoanOfficer.next(this._internalContactToLoanOfficerAdapter.adapt(loanOfficer));
            }
            else if (this.isLoanOfficer(loanOfficer)) {
                this._currentLoanOfficer.next(loanOfficer); 
            }
        }else{
            this._currentLoanOfficer.next(this.getDefaultLoanOfficer());
        }

        return this._currentLoanOfficer;
    }
        

    private getDefaultLoanOfficer(): LoanOfficer {
        const defaultLoanOfficer = new LoanOfficer();
        defaultLoanOfficer.firstName = 'nbkc';
        defaultLoanOfficer.lastName = 'bank';
        defaultLoanOfficer.loanOfficerEmail = 'apply.now@nbkc.com';
        defaultLoanOfficer.nmlsNum = '409631';
    
        return defaultLoanOfficer;
    }

    private isLoanOfficer = (model: any): model is LoanOfficer => {
        return typeof model === 'object' && Boolean(model.firstName);
    }

    private isInternalContact = (model: any): model is InternalContact => {
        return typeof model === 'object' && Boolean(model.FirstName);
    }
}
