import { ValidationRule } from '@nbkc/validation/dist/rules/validation-rule';
import moment from 'moment';

const defaultMessage: string = 'Please enter a valid date.';
const ruleName: string = 'validDate';

export class FutureDateRule extends ValidationRule {
    constructor(errorMessage?: string) {
        super(errorMessage || defaultMessage, ruleName);
    }

    public conditionForInvalid(value?: string): boolean {

        let isInvalid=  !moment(value, 'MM/DD/YYYY', true).isValid();

        if(isInvalid){
            return isInvalid;
        }

        let valueDate = new Date(value); 

        let currentDate = new Date(Date.now()); 

        let currentDateUtc = new Date(Date.UTC(currentDate.getFullYear(), currentDate.getMonth(), currentDate.getDate())).setHours(0); 

        let passedDateUtc = new Date(Date.UTC(valueDate.getFullYear(), valueDate.getMonth(), valueDate.getDate())).setHours(0);
        
        if(passedDateUtc > currentDateUtc){
            isInvalid = true;
        }

        return isInvalid;
    }
}