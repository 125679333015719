import { Component, DoCheck } from '@angular/core';
import { MaintenanceService } from '@services/maintenance.service';
import { Router } from '@angular/router';
import { KnownRoutes } from '@constants';

@Component({
	selector: 'app-maintenance',
	templateUrl: './maintenance.component.html',
	styleUrls: ['./maintenance.component.scss']
})
export class MaintenanceComponent implements DoCheck {
	constructor(public maintenanceService: MaintenanceService, public router: Router) {
	}

	ngDoCheck(): void {
		if (!this.maintenanceService.isAppOffline) {
			this.router.navigate([KnownRoutes.Home]);
		}
	}
}
