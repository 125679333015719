import { TrackerContextBase, ApplicationInsightsTrackerContext, GoogleAnalyticsTrackingContext, GoogleTagManagerTrackingContext } from '@nbkc/tracker';
import _ from 'lodash';

export class ApplicationTrackerContext extends TrackerContextBase {
	public applicationId?: string;
	public internalUserId?: string;
	public sectionName?: string;
	public email?: string;
	public authenticatedUserId?: string;
	public page?: string;
	public location?: string;
	public title?: string;

	public constructor(props: ApplicationTrackerContextProps = { }) {
		super();
		_.merge(this, props);
	}

	ai(): ApplicationInsightsTrackerContext {
		let aiContext = new ApplicationInsightsTrackerContext();
		aiContext.userId = this.email;
		aiContext.pageInfo = {
			page: this.page,
			location: this.location,
			title: this.title
		};
		aiContext.applicationId = this.applicationId;
		aiContext.sectionName = this.sectionName;
		aiContext.internalUserId = this.internalUserId;
		aiContext.pageInfo = _.omitBy(aiContext.pageInfo, _.isNil);
		aiContext = _.omitBy(aiContext, (value) => _.isEmpty(value) || _.isNil(value));
		return aiContext;
	}

	ga(): GoogleAnalyticsTrackingContext {
		const gaContext = new GoogleAnalyticsTrackingContext();
		gaContext.page = this.page;
		gaContext.location = this.location;
		if (this.sectionName) {
			gaContext.title = `${this.sectionName} | Home Loan Application | nbkc bank`;
		}
		gaContext.customDimensions = [this.applicationId, this.internalUserId, this.sectionName];
		return gaContext;
	}

	gtm(): GoogleTagManagerTrackingContext {
		const gtmContext = new GoogleTagManagerTrackingContext(); 
		gtmContext.page = this.page;
		gtmContext.location = this.location; 
		if(this.sectionName){
			gtmContext.title = `${this.sectionName} | Home Loan Application | nbkc bank`;
		}
		gtmContext.customDimensions = [this.applicationId, this.internalUserId, this.sectionName];
		return gtmContext;
	}
}

interface ApplicationTrackerContextProps {
	applicationId?: string;
	internalUserId?: string;
	sectionName?: string;
	page?: string;
	location?: string;
	title?: string;
	email?: string;
	authenticatedUserId?: string;
}
