import { InternalContact } from "@models/api/borrower-portal/internal-contact";
import { PortfolioLoanServicingForm } from "./portfolio-loan-servicing-form";
import { PortfolioRequestForm } from "./portfolio-request-form.model";

export class PortfolioForm {
    public id : string; 
    public stage : string;
    public requests : PortfolioRequestForm[];
    public servicing : PortfolioLoanServicingForm;
    public currentLoanOfficer : InternalContact; 
}