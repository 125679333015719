import { NgModule } from '@angular/core';
import { CardComponent } from './card/card.component';
import { CardHeaderComponent } from './card/header/card-header.component';
import { MatButtonModule } from '@angular/material/button';
import { MatInputModule } from '@angular/material/input';
import { CommonModule } from '@angular/common';
import { FormsModule, ReactiveFormsModule } from '@angular/forms';
import { NgbModule } from '@ng-bootstrap/ng-bootstrap';
import { CardContentComponent } from './card/content/card-content.component';
import { CardFooterComponent } from './card/footer/card-footer.component';
import { MatFormFieldModule } from '@angular/material/form-field';
import { ButtonComponent } from './button/button.component';
import { MatProgressSpinnerModule } from '@angular/material/progress-spinner';
import { IconComponent } from './icon/icon.component';
import { CardRightCornerBannerComponent } from './card/header/right-corner-banner/card-right-corner-banner.component';
import { CardRightCornerIconComponent } from './card/header/right-corner-icon/card-right-corner-icon.component';
import { ModalComponent } from './modal/modal.component';
import {MatBadgeModule} from '@angular/material/badge';
import { MatIconModule } from '@angular/material/icon';
import { TextMaskModule } from './utilities/angular2-text-mask/text-mask';



@NgModule({
  declarations: [
    CardComponent,
    CardHeaderComponent,
    CardContentComponent,
    CardFooterComponent,
    ButtonComponent,
    IconComponent,
    CardRightCornerBannerComponent,
    CardRightCornerIconComponent,
    ModalComponent
  ],
  imports: [
    MatButtonModule,
    MatInputModule,
    CommonModule,
		FormsModule,
		NgbModule,
    ReactiveFormsModule,
    TextMaskModule,
    MatFormFieldModule,
    MatProgressSpinnerModule,
    MatBadgeModule,
    MatIconModule
  ],
  exports: [
    CardComponent,
    CardHeaderComponent,
    CardContentComponent,
    CardFooterComponent,
    ButtonComponent,
    CardRightCornerBannerComponent,
    CardRightCornerIconComponent,
    ModalComponent
  ]
})
export class SharedComponentsModule { }
