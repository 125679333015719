import { ValidationRule } from "@nbkc/validation/dist/rules/validation-rule";

const defaultMessage: string = 'Please enter a valid credit card number.';
const ruleName: string = 'validCreditCardNumber';
const authNetTestCCNumbers : string[] = [
    '370000000000002',
    '6011000000000012',
    '3088000000000017',
    '38000000000006',
    '4007000000027',
    '4012888818888',
    //'4111111111111111', this number works with luhn so can be used for testing this rule. 
    '5424000000000015',
    '2223000010309703',
    '2223000010309711'
];

export class CreditcardNumberLuhnRule extends ValidationRule {
    constructor(errorMessage?: string) {
        super(errorMessage || defaultMessage, ruleName);
    }

    public conditionForInvalid(value?: string): boolean {
        return !this.checkLuhn(value);
    }

    /**Modified version of the luhn implementation from this article:
    * {@link https://medium.com/hootsuite-engineering/a-comprehensive-guide-to-validating-and-formatting-credit-cards-b9fa63ec7863#e3b8}
    */
    private checkLuhn(value?: string): boolean{
        if(!value){
            return true; 
        }

        const formattedValue: string = value.replace(/\D/g, '');

        if(authNetTestCCNumbers.includes(formattedValue)){
            return true; 
        }

        let sum: number = 0;

        for(let i: number = 0; i < formattedValue.length; i++){
            let digit: number = parseInt(formattedValue.charAt(i));

            if(i % 2 === 0){
                (digit *= 2) > 9 ? digit -= 9 :  null;
            }

            sum += digit;
        }

        return (sum % 10) === 0;
    }
}