import { Component, OnInit, OnDestroy } from '@angular/core';
import { ConfigService } from '@services/config.service';
import { ErrorService } from '@services/error.service';

@Component({
	selector: 'app-consumer-connect',
	templateUrl: './consumer-connect.component.html',
	styleUrls: ['./consumer-connect.component.scss']
})
export class ConsumerConnectComponent implements OnInit, OnDestroy {
	public SiteId;
	constructor(
		public errorService: ErrorService,
		public config: ConfigService
	) {

	}

	ngOnInit(): void {		
		let script = document.createElement('script');
			script.type = 'text/javascript';
			script.src = 'https://widget.ellieservices.com/latest/launcher.js';
			document.head.appendChild(script);

		this.SiteId = this.config.CONSUMER_CONNECT_SITE_ID;
	}

	ngOnDestroy(): void {
		this.errorService.dismiss();
	} 	
}
