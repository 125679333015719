export class ErrorServiceOptions{
    public data? : Error | undefined;
    public delay?: number = 0; 
    public display?: string = 'error-page';
    public message?: string = 'We\'re experiencing some unexpected issues right now.';
    public retryAction?: () => void | undefined;
    public redirectOnProd?: string[] | undefined;
    public retryText?: string = 'Retry';
    public title?: string = 'Oops!';
    public success?: boolean = false;

    constructor(init?:Partial<ErrorServiceOptions>){
        Object.assign(this, init);
    }
}