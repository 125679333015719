<app-app-header></app-app-header>
    <div class="em-widget"
      data-width="100%"
      data-height="1000px"
      data-min-height="800px"
      [attr.data-site-id]="config.CONSUMER_CONNECT_SITE_ID" 
      data-name="ecc-borrower-portal"
      data-aria-label="Borrower Portal"
      data-log-level="4"
      >
    </div>