import _ from 'lodash';
import { Section } from '@models/section.model';
import { ApplicationService } from './application.service';
import { ErrorService } from './error.service';
import { SectionService } from './section.service';
import { Injectable } from '@angular/core';
import { Router } from '@angular/router';
import { SectionNames } from '@constants';
import { NavigationDetails } from '@models/navigation-details.model';
import { KnownRoutes } from '@constants';
import { BehaviorSubject, Observable } from 'rxjs';

@Injectable({
	providedIn: 'root'
})
export class NavigationService {
	
	public navDirection: string | null;

	private _navigationTarget: BehaviorSubject<string> = new BehaviorSubject(SectionNames.unknown);
	private blockNav: boolean = false;

	constructor(
		private applicationService: ApplicationService,
		private errorService: ErrorService,
		private sectionService: SectionService,
		private router: Router
	) { 
	}

	/**
	 * Hooks into the navigation service {@link selectSection()} function to allow components to perform logic before 
	 * the navigation service loads its target {@link Section} as part of the apply workflow. Changes made to component 
	 * forms using this will be saved in the {@link selectSection()} save call. 
	 * 
	 * @returns The {@link Section} name that navigation service is about to load
	 */
	public onNavigationEvent(): Observable<string> {
		return this._navigationTarget.asObservable();
	}

	private publishNavigationEvent(target: Section): void {
		this._navigationTarget.next(target.name);
	}

	public getNavigationDetails(sectionName: string, isReviewed: boolean): NavigationDetails {
		const sectionNav = new NavigationDetails();

		const section = this.sectionService.getSection(sectionName);

		if (section) {
			const isSectionVisited = this.sectionService.isVisited(sectionName);

			sectionNav.title = section.displayName;
			sectionNav.isHidden = section.hiddenFromNavigation || section.disabled;
			sectionNav.complete = this.sectionService.isComplete(sectionName);
			sectionNav.current = this.sectionService.isCurrentSection(sectionName);
			sectionNav.valid = this.sectionService.isValid(sectionName) && isSectionVisited;

			if (section.hiddenFromReviewPage) {
				sectionNav.showValidity = false;
			} else {
				sectionNav.showValidity = isSectionVisited || isReviewed;
			}
		} else {
			sectionNav.title = SectionNames.unknown;
			sectionNav.isHidden = false;
			sectionNav.valid = false;
			sectionNav.current = false;
			sectionNav.complete = false;
			sectionNav.showValidity = true;
		}

		return sectionNav;
	}

	public determineSectionOrderNav(sectionToNavigate: Section, animate: boolean): void {
		if (animate) {
			const firstInList = _.find(
				this.sectionService.appSections,
				(section) => section === sectionToNavigate || section === this.sectionService.current
			);
			if (firstInList === this.sectionService.current) {
				this.navDirection = 'down';
			} else {
				this.navDirection = 'up';
			}
		} else {
			this.navDirection = '';
		}
	}

	public navigateToNextSection(animate: boolean = true): void {
		if (this.sectionService.next) {
			this.selectSection(this.sectionService.next, animate);
		}
	}

	public navigateToSection(sectionName: string, animate: boolean = true): void {
		if (this.sectionService.current && sectionName === this.sectionService.current.name) {
			return;
		}
		const section = this.sectionService.getSection(sectionName);
		if (section) {
			this.selectSection(section, animate);
		}
	}

	public selectSection(section: Section, animate: boolean = true): void {
		if (this.blockNav) {
			return;
		}
		this.applicationService.currentApplication.resetValidationCacheTree();
		
		this.publishNavigationEvent(section);

		if (this.sectionService.current && !this.sectionService.current.progress.visited) {
			this.sectionService.current.progress.visited = true;
		}

		this.applicationService.save().subscribe();

		this.determineSectionOrderNav(section, animate);

		setTimeout(() => {
			if (section.name === SectionNames.review) {
				this.applicationService.turnOnSavingIndicator();
				this.applicationService.save().subscribe((response) => {
					this.applicationService.turnOffSavingIndicator(true);
					if (response && response.Success) {
						this.transitionState(section);
					} else {
						this.showSaveFailed(response, section, animate);
					}
				});
			} else {
				this.transitionState(section);
			}
		});
	}

	public transitionState(section: Section): void {
		this.router.navigate([KnownRoutes.Apply, this.applicationService.currentApplication.id, section.name]).then(() => { });
	}

	public disableNav(): void {
		this.blockNav = true;
	}

	public enableNav(): void {
		this.blockNav = false;
	}

	private showSaveFailed(response: any, section: Section, animate: boolean): void {
		this.errorService.handle({
			data: response,
			display: 'toast',
			message: 'Cannot save your application.',
			retryAction: () => {
				this.selectSection(section, animate);
			}
		});
	}
}
