import { PortfolioApplicationSummary } from "./portfolio-application-summary";
import { PortfolioLoanSummary } from "./portfolio-loan-summary";
import { InternalContact } from "./internal-contact";
import { PortfolioRequest } from "./portfolio-request";

export class PortfolioApi {
    public Name : string; 
	public Id : string; 
    public Status : string;
    public Application : PortfolioApplicationSummary; 
    public Loan : PortfolioLoanSummary;
    public Requests : PortfolioRequest[];
    public InternalContact : InternalContact; 
    public Stage : string;
    public Type : string; 
}