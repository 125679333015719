import { AdapterBase } from '@utilities/adapter-base';
import { FulfillmentDocumentApi } from '@models/api/fulfillment-documents';
import { UploadedDocument } from '@models/uploaded-document.model';

export class FulfillmentDocumentApiToUploadedDocumentAdapter extends AdapterBase<FulfillmentDocumentApi, UploadedDocument> {
	public adapt(source: FulfillmentDocumentApi): UploadedDocument {
		const myDoc = new UploadedDocument();
		myDoc.documentId = source.DocumentId;
		myDoc.filename = source.Filename;
		myDoc.displayCheck = false;
		myDoc.contentType = source.ContentType;
		return myDoc;
	}
}
