import { PortfolioApi } from "@models/api/borrower-portal/portfolio-api";
import { PortfolioForm } from '@models/form/borrower-portal/portfolio-form.model';
import { AdapterBase } from "@utilities/adapter-base";
import { PortfolioRequestToPortfolioRequestFormAdapter } from "./portfolio-request-to-portfolio-request-form";
import { PortfolioServicingApiToPortfolioServicingFormAdapter } from "./portfolio-servicing-api-to-portfolio-servicing-form-adapter";

export class PortfolioApiToPortfolioFormAdapter extends AdapterBase<PortfolioApi, PortfolioForm> {
    public adapt(portfolioApi : PortfolioApi) : PortfolioForm {
        let portfolio = new PortfolioForm(); 
        portfolio.id = portfolioApi.Id; 
        portfolio.stage = portfolioApi.Stage;

        let portfolioRequestToPortfolioRequestFormAdapter = new PortfolioRequestToPortfolioRequestFormAdapter(); 
        portfolio.requests = portfolioRequestToPortfolioRequestFormAdapter.adaptCollection(portfolioApi.Requests);

        if(portfolioApi.Loan.Servicing){
            let portfolioServicingApiToPortfolioServicingFormAdapter = new PortfolioServicingApiToPortfolioServicingFormAdapter(); 
            portfolio.servicing = portfolioServicingApiToPortfolioServicingFormAdapter.adapt(portfolioApi.Loan.Servicing);
        }
        return portfolio; 
    }
}