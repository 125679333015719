import { LoanApplicationApi } from './loan-application';
import { QualificationResultApi } from './qualification-result';
import { Choice } from './choice';
import { InternalContact } from './borrower-portal/internal-contact';

export class SupplementApplicationResultsApi {
	public ModifiedApplication: LoanApplicationApi;
	public QualificationResults: QualificationResultApi[];
	public IsQualifiedForSubmission: boolean;
	public Choices: Record<string, Choice[]>;
	public InternalContact: InternalContact; 
}
